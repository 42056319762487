import React, { useState } from "react";
import PropTypes from "prop-types";

import {
	Separator,
	Text,
	Grid,
	Col,
} from "@website-builder/ui/shared/elements";
//styles
import {
	VerticalTabsPanelWrapper,
	VerticalTabsHeadingWrapper,
	VerticalTabContentWrapper,
	BottomAddOnWrapper,
} from "./styles";

const VerticalTab = ({
	children,
	heading,
	variant,
	tabSpan,
	contentSpan,
	initialTab = children[0]?.props?.name,
	onChange,
	bottomAddOn,
	...restProps
}) => {
	// state
	const [selectedTab, setSelectedTab] = useState(initialTab);

	//tab and content span
	const [tabStartsFrom] = tabSpan;
	const tabSpanUpto = tabSpan[1] - tabSpan[0] + 1;
	const [contentStartsFrom] = contentSpan;
	const contentSpanUpto = contentSpan[1] - contentSpan[0] + 1;

	// inherits parents properties
	const inheritChildrenWithParentProps = () => {
		return React.Children.map(children, (child) => {
			// checks isValidElement
			if (React.isValidElement(child)) {
				// appends tabs props to its children
				return React.cloneElement(child, {
					variant,
					setSelectedTab,
					selectedTab,
					onChange,
				});
			}
			return child;
		});
	};

	// returns the selected tab content
	const renderTabContent = () => {
		const toBeRendered = children.find(
			(child) => child?.props?.name === selectedTab,
		);
		return toBeRendered?.props?.children;
	};
	// render bottom component
	const renderBottomAddOn = () => {
		return (
			<BottomAddOnWrapper>
				<Separator
					style={{
						marginBottom: "1.5rem",
					}}
				/>
				{bottomAddOn}
			</BottomAddOnWrapper>
		);
	};

	const renderVerticalTabItems = () => {
		return (
			<VerticalTabsPanelWrapper variant={variant}>
				{heading && (
					<VerticalTabsHeadingWrapper variant={variant}>
						<Text
							variant="paragraph_M_bold"
							color={{ base: "secondary", color: "gray", shade: "dark" }}
						>
							{heading}
						</Text>
					</VerticalTabsHeadingWrapper>
				)}
				{inheritChildrenWithParentProps()}
				{bottomAddOn && renderBottomAddOn()}
			</VerticalTabsPanelWrapper>
		);
	};

	const VerticalTabContent = () => {
		return (
			<VerticalTabContentWrapper>
				{renderTabContent()}
			</VerticalTabContentWrapper>
		);
	};

	return (
		<div {...restProps}>
			<Grid>
				<Col start={tabStartsFrom} span={tabSpanUpto} mdSpan={6}>
					{renderVerticalTabItems()}
				</Col>
				<Col start={contentStartsFrom} span={contentSpanUpto} mdSpan={6}>
					<VerticalTabContent />
				</Col>
			</Grid>
		</div>
	);
};

const arrayOf2Validation = (props, propName) => {
	if (
		!Array.isArray(props[propName]) ||
		props[propName].length != 2 ||
		!props[propName].every(Number.isInteger)
	) {
		return new Error(`${propName} needs to be an array of two numbers`);
	}

	return null;
};
VerticalTab.defaultProps = {
	variant: "default",
	tabSpan: [1, 3],
	contentSpan: [4, 12],
};
VerticalTab.propTypes = {
	heading: PropTypes.string, // defines tab heading
	tabSpan: (props, propName) => arrayOf2Validation(props, propName), // defines tab heading
	contentSpan: (props, propName) => arrayOf2Validation(props, propName), // defines tab heading
	variant: PropTypes.oneOf(["default", "withIcon"]), // defines tab variant
	initialTab: PropTypes.string, // defines selected tab
	onChange: PropTypes.func, // defines tab heading
	bottomAddOn: PropTypes.element, // defines bottom component of tab
};
export default VerticalTab;
